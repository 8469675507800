<template>
    <v-container fluid>
        <PageHeaderSlot>
            <template slot="top-right-slot">
                <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateTutor' }">
                    <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
                    新增導師
                </v-btn>
            </template>
        </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            @options-update="onTableOptionsChange"
            actionViewRouteLink="TutorDetails"
        ></Datatable>
    </v-container>
</template>

<script>
import { mdiPlusCircleOutline } from '@mdi/js';
import Datatable from '@/components/Datatable.vue';

export default {
    name: 'TutorList',
    components: {
        Datatable,
    },
    data: () => ({
        icon: {
            mdiPlusCircleOutline
        },
        tableHeaders: [
            { value: 'id', text: '導師ID' },
            { value: 'tutor_name', text: '導師' },
            { value: 'account', text: '帳戶' },
            { value: 'phone', text: '電話' },
            { value: 'actionView', text: '', align: 'end' },
        ],

        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
    }),
    methods: {
        async getTutorData() {
            this.tableLoading = true;

            try {
                let payload = {
                    filter_limit: this.tableLimit,
                    filter_page: this.tablePage - 1,
                };

                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center = [selectedSchool];
                }

                const { data, total } = await this.$Fetcher.GetTutors(payload);
                this.tableData = data.map(el => {
                    return {
                        id: el.id,
                        tutor_name: el.name,
                        account: el.username,
                        phone: el.phone
                    }
                });
                
                this.tableItemTotal = total;
            } catch(err) {
                this.tableData = [];
            } finally {
                this.tableLoading = false;
            }
        },
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getTutorData();
        },
    },
    async created() {
        await this.getTutorData();
    }
}
</script>